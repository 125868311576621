
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}


.content-area {
  width: calc(66.6% - 1rem);
  margin-right: 1rem;
  float: left;
}

.sidebar {
  color: black;
}

.main-header {
  height: 3rem;
}

.logo {
  width: 20%;
  float: left;
  height: 100%;
  line-height: 3rem;
}

.main-menu {
  width: 79%;
  float: right;
  height: 100%;
  line-height: 3rem;
  text-align: right;
}

.main-menu ul {
  margin: 0;
  padding: 0;
}

.main-menu__item {
  list-style-type: none;
  padding: 0;
  display: inline-block;
  font-size: 1.1rem;
  margin-right: 0.5rem;
}

.main-menu .main-menu__item:last-of-type {
  margin-right: 0;
}

.content-area-wrapper, .sponsors-wrapper, .main-header {
  margin-bottom: 2rem;
  
}

.sponsors {
  display: table;
  border-spacing: 1rem;
  width: 100%;
}

.sponsor {
  width: 20%;
  display: table-cell;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 0.625rem;
}

.sponsor img {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

@media all and (max-width: 992px) {
  .container {
    margin: 0 1rem;
  }
  
  .sponsors {
    border-spacing: 0.5rem;
  }
}

@media all and (max-width: 768px) {
  html {
    font-size: 14px;
  }
  
  .content-area, .sidebar {
    float: none;
  }

  .content-area {
    width: 100%;
    margin-bottom: 2rem;
  }

  .sidebar {
    width: 66.6%;
  }
  
  .sponsors, .sponsor {
    display: block;
  }

  .sponsor {
    width: 100%;
    margin: 1rem 0;
  }
}

@media all and (max-width: 576px) {
  .main-menu, .logo {
    float: none;
    width: 100%;
  }

  .main-menu {
    text-align: left;
    margin-top: 2rem;
  }

  .main-header {
    height: auto;
  }

  .logo, .main-menu {
    line-height: initial;

    
  }
}





/* //first component  */
.Dragdrop{
    /* background-color: #ededed;
    border-style: dashed;
    border-color: #836767;
    width: 400px;
    height: 188px;
    left: 66.5%;
    position: relative; */

    background-color: #ededed;
    border-style: dashed;
    border-color: #836767;
    width: 400px;
    height: 188px;
    left: 66.5%;
    position: absolute;
}

.dragbtn{
    background: #6870af;
    border-radius: 38px;
    color: #fff;
    display: flex;
    font-family: Arial;
    font-size: 22px;
    text-align: center;
    text-decoration: none;
    padding: 20px;
    margin: 0px 80px 0px 0px;
    justify-content: space-evenly;
    cursor: pointer;
  
}


.homeloader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
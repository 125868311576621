/* .tdimage1 {
  padding: 0px;
  border: 1px solid #e1e1e1;
  border-left: none;
  border-right: none;
  width: 18%;
} */

.tdimage1 {
  padding: 0px;
  border: 1px solid #e1e1e1;
  border-left: none;
  width: 4%;
}

.tdvaluecourse {
  padding: 6px 18px;
  border: 1px solid #e1e1e1;
  border-top: none;
  text-align: center;
}

.tdvaluecoursetitle_new {
  /* padding: 0px 0px 81px 14px; */
  padding: 8px;
  border: 1px solid #e1e1e1;
  border-top: none;
  /* width: 33%; */
}

.mainBoxCoupanSelect_new{
  display: flex;
  /* padding: 7px; */
}


.coursetype {
  /* padding: 6px -16px;
    border: 1px solid #e1e1e1;
    text-align: center;
    width: 12%; */

    border: 1px solid #e1e1e1;
    text-align: center;
    width: 15%;
}

.tdvaluethree {
  /* padding: 6px 2px;
  border: 1px solid #e1e1e1;
  border-left: none;
  border-right: none;
  text-align: center; */
    border: 1px solid #e1e1e1;
    border-left: none;
    border-right: none;
    text-align: center;
    width: 15%;
}

/* .mainBoxCoupanSelect_new ul {
  margin: 0;
  padding: 0;
}
.mainBoxCoupanSelect_new ul {
  display: flex;
  height: 14vh;
  list-style-type: none;
  flex-direction: column;
  flex-wrap: wrap;
  border: 1.2px solid #dfd2d2;
  width: 26vw;
}
.mainBoxCoupanSelect_new li {
  flex: 0 0 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.mainBoxCoupanSelect_new li:nth-child(1) {
  flex: 0 0 100%;
} */


/* .mainBoxCoupanSelect_new{
  display: flex;
  height: 14vh;
  list-style-type: none;
  flex-direction: column;
  flex-wrap: wrap;
  border: 1.2px solid #dfd2d2;
  width: 27vw;
} */

.tdvaluecoursenoid {
  /* border: 1px solid #e1e1e1;
  border-right: none;
  width: 3%;
  text-align: center; */

  padding: 0px 0px 81px 14px;
  border: 1px solid #e1e1e1;
  /* border-left: none; */
  width: 3%;
}

.tdvaluecourses{
  /* padding: 0px 0px 81px 14px;
  border: 1px solid #e1e1e1;
  border-left: none; */

    padding: 0px 0px 81px 14px;
    border: 1px solid #e1e1e1;
    border-left: none;
    width: 28%;
    


    

}

/* .tdData{
  display: flex;
} */

.imagesnap {
  width: 296px;
  height: 138px;
  object-fit: cover;
}

:where(.css-dev-only-do-not-override-190m0jy).ant-switch.ant-switch-checked {
  background: #65b57f;
}

:where(.css-dev-only-do-not-override-190m0jy).ant-switch {
  box-sizing: border-box;
  margin: 0;
  padding: 4px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 22px;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  min-width: 56px;
  height: 31px;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}

:where(.css-dev-only-do-not-override-190m0jy).ant-switch .ant-switch-handle {
  position: absolute;
  top: 7px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}

.imagecontainer {
  position: relative;
  display: inline-block;
}

.imagetext {
  position: absolute;
  top: 71%;
  left: 80%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  opacity: 0.8;
  padding: 7px;
  border-radius: 5px;
}

/* Pagination container */
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-top: 0px;
}

/* Pagination item (page number) */
.pagination li {
  /* margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer; */


  margin: 0 5px;
  padding: 5px 1px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.pagination li a{
  padding: 5px;
}
/* Active page */
.pagination li.active {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
}

/* Previous and Next buttons */
.pagination li.previous,
.pagination li.next {
  font-weight: bold;
}

/* Disabled buttons */
.pagination li.disabled {
  pointer-events: none;
  opacity: 0.6;
}

/* Break item (ellipsis) */
.pagination li.break {
  margin: 0 5px;
  padding: 0;
  font-weight: bold;
}

/* Hover effect on pagination items */
.pagination li:hover {
  /* background-color: #f5f5f5;
  border: 1px solid #ccc; */
}

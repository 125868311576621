/* .bg{
    width: 100%;
    height: 100vh;
    display: grid;
    background-color: #6870af;
    place-items:center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
} */
button:focus {
  border: 1px solid black;
}

.error-message {
  color: red;
}

.success-message {
  color: rgb(39, 229, 90);
  margin-top: 5%;
}

.spanone {
  margin: 17px 0px 0px 17px;
}

.bgone {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  border-radius: 37px;
  padding: 14px 24px;
  border: none;
  background: #8389bf;
  color: #fff;
  margin: -26px 75px 0px 75px;
}

.bgone[disabled] {
  cursor: not-allowed; /* Change cursor to indicate it's not clickable */
  opacity: 0.6; /* Reduce opacity to indicate it's disabled */
  background-color: gray; /* Change background color to gray */
  /* You can add more styles here to adjust appearance when disabled */
}


.bg {
  width: 100%;
  height: 100vh;
  display: grid;
  background-color: #6870af;
  place-items: center;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

@media screen and (min-width: 1100px) {
  .bg {
    grid-template-columns: 1fr 1fr;
  }

  .forms {
    display: grid;
    align-content: center;
    grid-template-columns: auto;
    grid-template-rows: 80px 50px 75px 75px 14px 42px;
    row-gap: 12px;
    height: 441px;
    border-radius: 37px;
    background-color: #fff;
    padding: 8%;
  }
}

.container-logo {
  text-align: center;
  color: #fff;
}

.forms {
  display: grid;
  align-content: center;
  grid-template-columns: auto;
  grid-template-rows: 80px 50px 75px 75px 14px 42px;
  row-gap: 12px;
  height: 441px;
  border-radius: 37px;
  background-color: #fff;
  padding: 8%;
}


.forms-update {
  /* display: grid;
  align-content: center;
  grid-template-columns: auto;
  grid-template-rows: 80px 71px 75px 75px 16px 42px;
  row-gap: 11px;
  height: 441px;
  border-radius: 37px;
  background-color: #fff;
  padding: 8%; */

    display: grid;
    align-content: center;
    grid-template-columns: auto;
    grid-template-rows: 80px 71px 75px 75px 17px 41px;
    row-gap: 10px;
    border-radius: 37px;
    background-color: #fff;
    padding: 8%;

}

.loginmain {
  position: relative;
  margin: 25px 0px 0px 0px;
}

.logininput {
  width: 100%;
  height: 143%;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 10px 0px 10px 20px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  font-size: x-large;
}

.forgetpasstext {
  position: absolute;
  right: 3px;
  top: 21px;
  bottom: 3px;
  border: 0;
  color: #7f86b9;
  outline: none;
  margin: 0;
  padding: 0 10px;
  text-decoration: underline;
  cursor: pointer;
}

.forgetpassworddiv {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.logininput2 {
  width: 100%;
  height: 143%;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 10px 129px 10px 20px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  font-size: x-large;
}

.logininput3 {
  border: 1px solid #ccc;
  border-radius: 15px;
  box-sizing: border-box;
  font-size: x-large;
  height: 143%;
  line-height: 1;
  outline: none;
  padding: 9px;
  width: 100%;
  margin-right: 129px;
}

.bgtwo {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  border-radius: 47px;
  padding: 14px 24px;
  border: none;
  background: #8389bf;
  color: #fff;
  margin: 5px 106px 0px 106px;
}

.spantwo {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.formstwo {
  display: grid;
  align-content: center;
  grid-template-columns: auto;
  grid-template-rows: 80px 50px 75px 75px 14px 42px;
  row-gap: 12px;
  height: 370px;
  /* width: 446px; */
  border-radius: 37px;
  background-color: #fff;
  padding: 8%;
}

/* .logininput2 {
    width: 100%;
    height: 143%;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 10px 0px 10px 20px;
    line-height: 1;
    box-sizing: border-box;
    outline: none;
    font-size: x-large;
  } */

.spanthree {
  color: red;
}

.formsthree {
  display: grid;
  align-content: center;
  grid-template-columns: auto;
  grid-template-rows: 80px 50px 75px 75px 14px 42px;
  row-gap: 12px;
  height: 370px;
  /* width: 446px; */
  border-radius: 37px;
  background-color: #fff;
  padding: 8%;
}

.bgthree {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  border-radius: 47px;
  padding: 14px 24px;
  border: none;
  background: #8389bf;
  color: #fff;
  margin: -47px 106px 0px 106px;
}

.logininputfour {
  width: 100%;
  height: 103%;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 10px 129px 10px 20px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  font-size: x-large;
}

/* .lang {
  position: absolute;
  top: 39px;
  right: 10px;
} */




.header-app-points {
  display: flex;
  justify-content: space-between;
  padding: 8px 0px 0px 0px;
}

.tdimage-point{
    padding: 0px;
    border: 1px solid #e1e1e1;
    border-left: none;
    border-top: none;
    width: 34%;
}

.box-box-btn-acc {
  padding-top: 48px;
}

.box-parent-acc{
display: grid;
grid-template-columns: repeat(5, 1fr);
grid-template-rows: repeat(5, 1fr);
grid-column-gap: 0px;
grid-row-gap: 0px;
}

.box-one-acc { grid-area: 1 / 1 / 2 / 3; }
.box-two-acc { grid-area: 1 / 2 / 2 / 6; }
.box-three-acc { grid-area: 2 / 1 / 3 / 3; }
.box-four-acc { grid-area: 2 / 2 / 3 / 6; }

.box-parent {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 27px;
  overflow-y: auto;
  height: 85vh;
}

.box-one {
  grid-area: 1 / 1 / 2 / 2;
   
}
.box-two {
  grid-area: 2 / 1 / 3 / 2;
   
}
.box-three {
  grid-area: 2 / 2 / 3 / 5;
   
}
.box-four {
  grid-area: 3 / 1 / 4 / 2;
   
}
.box-five {
  grid-area: 3 / 2 / 4 / 7;
   
}
.box-six {
  grid-area: 4 / 1 / 5 / 2;
   
}
.box-seven {
  grid-area: 4 / 2 / 5 / 5;
   
}
.box-eight {
  grid-area: 5 / 1 / 6 / 2;
   
}
.box-nine {
  grid-area: 5 / 2 / 6 / 7;
   
}
.box-ten {
  grid-area: 6 / 1 / 7 / 2;
   
}
.box-eleven {
  grid-area: 6 / 2 / 7 / 8;
   
}

.box-div12 {
  grid-area: 7 / 1 / 8 / 2;
}
.box-div13 {
  grid-area: 7 / 2 / 8 / 6;
}
.box-Addbtn{
    padding-top: 4px;
}

.box-removebtn{
    margin-left: 5px;
}
.box-input{
    margin-bottom: 6px
}
.box-box-btn{
    padding-top: 20px;
}


.box-input-class{
    border: 1px solid rgba(0, 0, 0, 0.16);
    height: 25px;
    padding: 5px 10px 5px 5px;
    font-size: 15px;
    border-radius: 3px;
    width: 400px;
    outline: none;
}

.box-box-remove{
    background-color: #ffffff;
    border: none;
    color: red;
    padding: 5px 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}
box-box-remove[disabled]{
    color: #c6c6c6;
    cursor: not-allowed;
  }
.box-box-add{
    background-color: #ffffff;
    border: none;
    color: #42d0a0;
    padding: 5px 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}

box-box-add[disabled]{
    color: #c6c6c6;
    cursor: not-allowed;
  }
  

  .box-handle-save{
    background-color: #6870af;
    border: none;
    color: white;
    padding: 15px 60px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 10px;
}

.box-dropdownforCountry {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    flex: 1; /* Expand to fill available space */
  }

select.box-dropdownforCountry-no {
    appearance: none;
    /* background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.65rem auto; */

    background-image: linear-gradient(45deg, transparent 50%, #cc9933 60%), linear-gradient(135deg, #cc9933 40%, transparent 50%) !important;
  background-position:
    calc(100% - 30px) 14px,
    calc(100% - 20px) 14px,
    100% 0;
  background-size:
    10px 10px,
    10px 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  }




  .constraintcard{
    color: black;
    background-color: powderblue;
    padding: 5px 10px;
    border-radius: 3px;
    margin: 3px;
    width: fit-content;
  }
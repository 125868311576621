.mainpage {
  display: flex;
  justify-content: space-between;
  margin: 28px 69px 0px 82px;
  /* width: calc(100% - 250px);
    padding: 40px;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0; */
}

.tabledata {
  /* padding: 15px; */
  /* padding: 50px 10%; */

  padding: 50px 0% 1% 18%;
  /* background-color: #999999; */
}

.Change_Password {
  color: #5eaeff;
  /* padding: 39px; */
  /* font-size: x-large; */
  font-size: large;
  font-weight: bolder;
  /* font-weight: 500; */
  cursor: pointer;
}

.Disabled {
  color: #ff6e0d;
  padding: 27px;
  font-size: x-large;
  font-weight: 500;
  cursor: pointer;
}

.Delete_User {
  color: #ff6e0d;
  /* padding: 39px; */
  /* font-size: x-large;
  font-weight: 500; */
  font-size: large;
  font-weight: bold;
  cursor: pointer;
}

.outletDiv {
  position: relative;
  width: 80%;
}

.btn_add {
  border-radius: 9px;
  padding: 8px 36px 6px 36px;
  border: none;
  margin: 0px 0px 0px 0px;
  background: #6870af;
  color: #fff;
  cursor: pointer;
}

.wrapper {
}

.disabled-button {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
  /* Add any other styles to visually indicate a disabled button */
}


.usertext {
  /* font-size: x-large;
  font-weight: 500; */
  font-size: large;
  font-weight: bold;
}

.ptext {
  font-size: x-large;
  font-weight: 500;
  color: darkgray;
}

/* //search input */
.search-container form {
  position: relative;
}
.search-container form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
  background: transparent;
  border: transparent;
  font-size: 20px;
  color: #100f10;
  cursor: pointer;
  outline: 0;
}

.tdData {
  padding: 6px 7px;
  border: 1px solid #e1e1e1;
  border-left: none;
  border-top: none;
  border-right: none;
}

.tdvalue {
  padding: 6px 6px;
  border: 1px solid #e1e1e1;
  border-left: none;
  border-top: none;
}

.tdvaluetwo {
  padding: 6px 18px;
  border: 1px solid #e1e1e1;
  border-left: none;
  border-top: none;
  border-right: none;
  text-align: center;
}
.container-logo {
  text-align: center;
  /* color: #fff; */
}



.gray-text {
  color: rgb(163, 163, 163);
}



.disable-button {
  color: #ff6e0d;
  padding: 27px;
  /* font-size: x-large;
  font-weight: 500; */
  font-size: large;
  font-weight: bold;
  cursor: pointer;
}

.enable-button {
  color: rgb(64 207 207);
  padding: 27px;
  /* font-size: x-large;
  font-weight: 500; */
  font-size: large;
  font-weight: bold;
  cursor: pointer;
}

dialog::backdrop {
  background: rgba(14, 1, 1, 0.25);
}


.bgonethree{
  display: inline-block;
    outline: none;
    cursor: pointer;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    border-radius: 37px;
    padding: 27px 24px;
    border: none;
    background: #6169b3;
    color: #fff;
    margin: -69px 75px 0px 75px;
}


/* //css for form */


.accordion {
    /* border: 1px solid #ccc; */
    border-radius: 4px;
    border-bottom: 1px solid #e5e5e5;
    /* border-top: 1px solid #e5e5e5; */
    /* margin: 20px;
    width: 100%; */
  }
  
  .accordion-item {
    border-bottom: 0px solid #ccc;
  }
  
  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: #fff;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  
  .right-align {
    display: flex;
    align-items: center;
  }
  
  .right-align > div {
    text-align: right;
  }
  
  .accordion-header:hover {
    /* background-color: #f7f7f7; */
  }
  
  .accordion-icon {
    transition: transform 0.3s ease;
    color: #cccccc;
    margin-left: 10px;
  }
  
  .accordion-icon.open {
    transform: rotate(180deg)
  }
  
  .accordion-content {
    /* padding: 10px; */
    background-color: #fff;
    font-family: Arial, sans-serif;
    font-size: 14px;
  }
  
  .date {
    color: #999999;
    font-size: 12px;
  }
  
  .right-align-content {
    text-align: right;
  }
  

  .accordion-text{
    font-size: 120%;
    color: #5a5a5a;
  }

  .accordion-text-bold{
    font-size: medium;
    font-weight: bolder;
    color: #000;
  }
.wrapper {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.navDiv {
  /* position:relative;
    width:20%; */
  width: 250px;
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  background: #6870af;
  color: #fff;
}

.outletDiv {
  /* position:relative; */
  width: 100%;

  margin: 0px 0px 0px 30px;
}

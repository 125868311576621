.carosuelPage {
  background-image: url("../../../../img/bg1.png");
  background-repeat: no-repeat;
}

/* * {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
} */


.linkclass {
  color: #000;
  padding: 17px 78px;
  display: flex;
  flex-direction: row;
  align-items: center;
}


.courseact{
  position: relative;
  right: 39px;
}


.headerNavHome {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 90px;
}


.headerNavHome nav ul {
  display: flex;
  flex-direction: row;
}

.headerNavHome nav ul li {
  list-style-type: none;
  margin-left: 3rem;
}


@media(max-width: 1440px) {

  .headerNavHome {
    margin-right: 312px;
  }

  .Smallbanner {
    background-color: rgb(247, 151, 157);
    padding: 8px;
    margin: 0px 329px 0px 291px !important;
    position: relative;
    top: -110px;
    opacity: 0.9;
}
  
.linkclass {
  color: #000;
  padding: 17px 47px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}


.courseact{
  position: relative;
  right: 203px;
}
  
  .content-area {
      width: calc(51.6% - 1rem);
      margin-right: 1rem;
      float: left;
  }
  
  .card3434 {
    position: relative;
    width: 345px !important;
    height: 159px;
    background-color: white;
    background-color: #ededed;
    border-style: dashed;
    border-color: #836767;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  }
  
  .btnHome {
      font-family: Roboto, sans-serif;
      font-size: 17px;
      color: #fff;
      background-color: #6870af;
      padding: 17px 89px !important;
      border-radius: 50px;
      transition: 1000ms;
      transform: translateY(0);
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      border: none;
  }

}




.card3434 {
  position: relative;
  width: 399px;
  height: 159px;
  background-color: white;
  background-color: #ededed;
  border-style: dashed;
  border-color: #836767;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}



.btn_one {
  background: #ff0099;
  background-image: -webkit-linear-gradient(top, #ff0099, #ff0099);
  background-image: -moz-linear-gradient(top, #ff0099, #ff0099);
  background-image: -ms-linear-gradient(top, #ff0099, #ff0099);
  background-image: -o-linear-gradient(top, #ff0099, #ff0099);
  background-image: linear-gradient(to bottom, #ff0099, #ff0099);
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
  padding: 17px 30px 17px 33px;
  text-decoration: none;
  cursor: pointer;
}

.btn_one:hover {
  text-decoration: none;
}

.btn_two {
  background: #33ccff;
  background-image: -webkit-linear-gradient(top, #33ccff, #33ccff);
  background-image: -moz-linear-gradient(top, #33ccff, #33ccff);
  background-image: -ms-linear-gradient(top, #33ccff, #33ccff);
  background-image: -o-linear-gradient(top, #33ccff, #33ccff);
  background-image: linear-gradient(to bottom, #33ccff, #33ccff);
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
  padding: 17px 30px 17px 33px;
  text-decoration: none;
  cursor: pointer;
}

.btn_two:hover {
  text-decoration: none;
}

.Recommendation {
  letter-spacing: 0px;
  text-decoration: underline;
  font-size: 15px;
  color: #fff;
  justify-content: space-between;
  position: absolute;
  left: 35px;
  top: 199px;
  text-underline-position: under;
  margin: 18px;
}

.fullscreen-Recommendation {
  letter-spacing: 0px;
  text-decoration: underline;
  font-size: 20px;
  color: #fff;
  justify-content: space-between;
  position: absolute;
  left: 35px;
  top: 8%;
  text-underline-position: under;
  margin: 18px;
}

.Carouselstyle {
  height: 170px;
}

.Carouselstylefullscreen {
  height: 216px;
}

/* MyCarousel.css */
.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background: white; /* Checked dot color (change as needed) */
}

.dot.inactive {
  background: black; /* Unchecked dot color (change as needed) */
}

/* Add any fullscreen-specific styles here */
.fullscreen {
  position: relative;
  top: 110px;
}

/* Add any not-fullscreen-specific styles here */
.not-fullscreen {
  position: relative;
  top: 8px;
  margin: 8px;
}

/* //add fullscreen-specific */
.buttonnextPrev {
  position: relative;
  top: -5vh;
  display: flex;
  justify-content: space-between;
  margin: 0px 20px 0px 20px;
  z-index: 1;
}

/* Add any not-fullscreen-specific */
.not-buttonnextPrev {
  position: relative;
  top: -9vh;
  display: flex;
  justify-content: space-between;
  margin: 0px 20px 0px 20px;
  z-index: 1;
}

.BrainhubCarouselItem--active .MyCarouselItem--active {
  padding: 0;
  border-style: dashed;
  filter: sepia(1);
  width: auto;
  height: 94%;
  color: aliceblue;
}

.MyCarouselItem--active-fullscreen {
  height: 92%;
}
.content-area-height {
  width: 176vh;
}

.tile {
  height: 148px;
  width: 230px;
  position: relative;
}

.image-container {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border: 3px solid transparent;
  box-shadow: 15px 15px 25px rgba(2, 28, 53, 0.05);
}

.body-app {
  padding: 0px 0px 46px 0px;
}
.hidden-checkbox:checked {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hidden-checkbox[type="checkbox"] {
  -webkit-appearance: none;
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
  border: 3px solid transparent;
  outline: none;
  z-index: 1000;
}

.hidden-checkbox[type="checkbox"]:after {
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  content: "\f111";
  font-size: 16px;
  top: 10px;
  left: 10px;
  color: #e2e6f3;
  background: white;
  border-radius: 50px;
}

.hidden-checkbox[type="checkbox"]:checked {
  border: 4px solid #6699ff;
}

.hidden-checkbox[type="checkbox"]:checked:after {
  font-weight: 900;
  content: "\f058";
  color: #6699ff;
}

.label-container {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: inline-block;
  border: 3px solid transparent;
}

.label-container img {
  position: absolute;
  top: 3px;
  left: 4px;
}

.hidden-checkbox {
  display: none;
}

.hidden-checkbox:checked + .label-container img {
  transform: scale(1.05);
}

.button-18 {
  align-items: center;
  background-color: #0a66c2;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto,
    "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell,
    "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Lucida Grande", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.button-18:hover,
.button-18:focus {
  background-color: #16437e;
  color: #ffffff;
}

.button-18:active {
  background: #09223b;
  color: rgb(255, 255, 255, 0.7);
}

.button-18:disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.3);
}

.buttondisabled {
  pointer-events: none;
  opacity: 0.5;
  /* Add any other styling you want for the disabled button */
}

/* .MyCarouselImagefullscreen{
  width: "100%",
  height: "100%",
  objectFit: "cover",
  padding: 0,
  borderStyle: "dashed",
  filter: "sepia(1)"
}

.MyCarouselImageNotfullscreen{
width: 100%;
height: 100%;
object-fit: cover;
padding: 0;
border-style: "dashed";
filter: sepia(1);
} */

.Smallbanner {
  background-color: rgb(247, 151, 157);
  padding: 8px;
  margin: 0px 406px 0px 417px;
  position: relative;
  top: -110px;
  opacity: 0.9;
}

.Smallbannernone {
  display: none;
}

.hide-banner {
  display: none;
}

/* ////////////new css */
.Featured {
  /* background-color: #fff;
  border-radius: 10px;
  border: 1px solid #aeaeae;
  box-shadow: 4px 4px 0 rgba(0,0,0,0.1); */

  /* padding: 30px;
  margin: 5px 15px;
  display: flex;
  text-align: left; */

  display: flex;
  text-align: left;
}

.Featured .Featured_img img {
  /* max-width: 220px;
  height: 142px;
  display: block;
  object-fit: cover;
  padding: 5px; */

    display: block;
    padding: 5px;
    width: 264px;
    height: 122px;
    object-fit: cover;
}

.Featured .Featured_text {
  padding-left: 25px;
  /* flex: 1 0; */
  /* display: grid; */
}

.card-container-app-new {
  align-items: start;
  display: grid;
  grid-gap: 9px;
  grid-template-columns: repeat(auto-fit, 482px);
  margin-left: 5%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 612px;
}

.hidden-checkbox-act:checked {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hidden-checkbox-act[type="checkbox"] {
  -webkit-appearance: none;
  /* position: relative; */
  /* height: 100%;
  width: 100%;
  cursor: pointer;
  border: 3px solid transparent;
  outline: none;
  z-index: 1000; */
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  z-index: 1000;
  margin: 0px;
}

.hidden-checkbox-act[type="checkbox"]:after {
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  content: "\f111";
  font-size: 16px;
  top: 10px;
  left: 10px;
  color: #e2e6f3;
  background: white;
  border-radius: 50px;
}

.hidden-checkbox-act[type="checkbox"]:checked {
  border: 4px solid #6699ff;
}

.hidden-checkbox-act[type="checkbox"]:checked:after {
  font-weight: 900;
  content: "\f058";
  color: #6699ff;
}

.label-container-act {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: inline-block;
  border: 3px solid transparent;
}

/* .label-container-act img {
  position: absolute;
  top: 3px;
  left: 4px;
} */

.hidden-checkbox-act {
  display: none;
}

.hidden-checkbox-act:checked + .label-container-act img {
  transform: scale(1.05);
}

.btnHome {
  font-family: Roboto, sans-serif;
  font-size: 17px;
  color: #fff;
  background-color: #6870af;
  padding: 17px 120px;
  border-radius: 50px;
  transition: 1000ms;
  transform: translateY(0);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border: none;
}

button.btnHome[disabled] {
  background-color: lightgray;
  cursor: not-allowed;
}


/* //loader_home */

.div_center_home{
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.loader_home {
  /* width: 48px;
  height: 48px; */
  width: 80px;
  height: 80px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 
  .img-loading {
    opacity: 0.5; /* or any other styles you want to apply */
  }



  .dropdown-input-wrapper {
    display: flex;
    align-items: center;
  }
  
  .dropdown {
    margin-right: 10px;
    width: 18%; /* Adjust width as needed */
  }
  
  .input-box {
    width: 82%;
  }

  
/* .loader_home {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #FFF;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}  */
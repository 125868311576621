.tdvalueOperaterefund {
  border: 1px solid #e1e1e1;
  border-left: none;
  border-top: none;
  border-right: none;
  text-align: center;
}

/* .refundstatus_no {
  font-size: medium;
  font-weight: 500;
  padding: 3px 20px;
  background-color: #f2f2f2;
} */

.refundstatus_no {
  font-size: medium;
  font-weight: 500;
  padding: 3px 20px;
  background-color: #f2f2f2;
  /* margin-right: 102px; */
}

.refundprocess{
  font-size: medium;
  font-weight: 600;
}
.refundstatus_yes {
  font-size: medium;
  font-weight: 500;
  padding: 3px 20px;
  color: #fff;
  background-color: #33cccc;
}

.refundstatus_refund {
  background: none;
  border: none;
  font-size: large;
  color: #fd9c5c;
  outline: none;
  cursor: pointer;
  padding: 8px;
  font-weight: 600;
}

.refundstatus_process {
  background: none;
  border: none;
  font-size: large;
  color: #33cccc;
  outline: none;
  cursor: pointer;
  padding: 8px;
  font-weight: 600;
}

.order-input-class{
  border: 1px solid rgba(0, 0, 0, 0.16);
  height: 25px;
  padding: 5px 10px 5px 5px;
  font-size: 15px;
  border-radius: 3px;
  width: 300px;
  outline: none;
}

.btn_order_search-refund {
  border-radius: 9px;
  padding: 8px 36px 6px 36px;
  border: none;
  /* margin: 0px 0px 0px 0px; */
  background: #6870af;
  color: #fff;
  cursor: pointer;
  margin-bottom: 16px;

}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.box-dropdownforType {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  flex: 1; /* Expand to fill available space */
  margin-bottom: 16px;
}

/* //modelcss */
/* .modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  justify-content: center;
  align-items: center;
}

.modal.show {
  display: flex;
} */

.modal {
  position: fixed;
  z-index: 999;
  background: white;
  border: 1px solid #ccc;
  /* padding: 20px; */
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: none;
}

.modal.show {
  display: block;
}

.modal-content {
  position: relative;
}

.textboxref{
    border: 1px solid rgba(0, 0, 0, 0.16);
    padding: 9px 30px;
    font-size: 15px;
    border-radius: 3px;
    width: max-content;
    outline: none;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #ebe3e3;
  /* width: 30%; */
  border-radius: 21px;
  /* width: 30%; */
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* //timeline */
.wrapper_home {
  /* width: 330px; */
  font-family: 'Helvetica';
  font-size: 12px;
}
.StepProgress {
  position: relative;
  padding-left: 45px;
  list-style: none;
  text-align: justify;
}
.StepProgress::before {
  display: inline-block;
  content: '';
  position: absolute;
  top: 0;
  left: 15px;
  width: 10px;
  height: 100%;
  border-left: 2px solid #CCC;
}
.StepProgress-item {
  position: relative;
  counter-increment: list;
}
.StepProgress-item:not(:last-child) {
  padding-bottom: 20px;
}
.StepProgress-item::before {
  display: inline-block;
  content: '';
  position: absolute;
  left: -30px;
  height: 100%;
  width: 10px;
}
.StepProgress-item::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  left: -37px;
  width: 12px;
  height: 12px;
  border: 2px solid #CCC;
  border-radius: 50%;
  background-color: #FFF;
}
.StepProgress-item.is-done::before {
  border-left: 2px solid #40cfcf;
}
.StepProgress-item.is-done::after {
  content: "✔";
  font-size: 10px;
  color: #ccff00;
  text-align: center;
  border: 2px solid #40cfcf;
  background-color: #ccff00;
}

.StepProgress-item.current::after {
  padding-top: 1px;
  top: 0;
  left: -37px;
  width: 12px;
  height: 12px;
  font-size: 14px;
  text-align: center;
  color: green;
  border: 2px solid #40cfcf;
  background-color: #ccff00;
}
.StepProgress strong {
  display: block;
}



/* _____pagination______ */
/* Pagination container */
.pagination_ref {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin-top: 0px;
}

/* Pagination item (page number) */
.pagination_ref li {
  /* margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer; */


  margin: 0 5px;
  padding: 5px 1px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.pagination_ref li{

  /* padding: 8px 8px; */
}
.pagination_ref li a{
  padding: 11px;
}
/* Active page */
.pagination_ref li.active {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #007bff;
}

/* Previous and Next buttons */
.pagination_ref li.previous,
.pagination_ref li.next {
  font-weight: bold;
}

/* Disabled buttons */
.pagination_ref li.disabled {
  pointer-events: none;
  opacity: 0.6;
}

/* Break item (ellipsis) */
.pagination_ref li.break {
  margin: 0 5px;
  padding: 0;
  font-weight: bold;
}

/* Hover effect on pagination items */
.pagination_ref li:hover {
  /* background-color: #f5f5f5;
  border: 1px solid #ccc; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.BrainhubCarouselItem div {
  position: relative;
  width: 100%;
  height: 94%;
  padding: 1%;
  /* z-index: 1000; */
  -webkit-filter: brightness(60%);
  filter: brightness(60%);
}

.BrainhubCarouselItem--active div {
  position: relative;
  width: 100%;
  height: 94%;
  padding: 1%;
  z-index: 1;
  -webkit-filter: brightness(100%);
  filter: brightness(100%);
    /* height: 94%;
    position: relative;
    width: 100%;
    z-index: 1; */
}



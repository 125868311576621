.tdimage {
  padding: 0px;
  border: 1px solid #e1e1e1;
  border-left: none;
  border-top: none;
  border-right: none;
  width: 21%;
}

.tdvaluecourse {
  padding: 6px 18px;
  border: 1px solid #e1e1e1;
  border-top: none;
  text-align: center;
}

.tdvaluecoursetitle {
  padding: 0px 0px 81px 14px;
  border: 1px solid #e1e1e1;
  border-top: none;
  /* width: 23%; */
}

.tdvaluestate {
  padding: 0px 0px 81px 14px;
  border: 1px solid #e1e1e1;
  border-top: none;
  width: 12%;
  text-align: center;
}

.tdvalueOperate {
  /* padding: 6px 76px;
    border: 1px solid #e1e1e1;
    border-left: none;
    border-top: none;
    border-right: none;
    text-align: center; */
  border: 1px solid #e1e1e1;
  border-left: none;
  border-top: none;
  border-right: none;
  text-align: center;
  width: 21%;
}

/* Add this CSS to your stylesheet */
button.actbtn[disabled] {
  background-color: lightgray; /* Set the background color for disabled state */
  cursor: not-allowed; /* Change cursor style to not-allowed */
  /* Add any other styling you want for the disabled state */
}

/* .tdData{
    display: flex;
  } */

.imagesnap {
  width: 296px;
  height: 138px;
  object-fit: cover;
}

:where(.css-dev-only-do-not-override-190m0jy).ant-switch.ant-switch-checked {
  background: #65b57f;
}

:where(.css-dev-only-do-not-override-190m0jy).ant-switch {
  box-sizing: border-box;
  margin: 0;
  padding: 4px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 22px;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  min-width: 56px;
  height: 31px;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}

:where(.css-dev-only-do-not-override-190m0jy).ant-switch .ant-switch-handle {
  position: absolute;
  top: 7px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}

.imagecontainer {
  position: relative;
  display: inline-block;
}

.imagetext {
  position: absolute;
  top: 71%;
  left: 80%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  opacity: 0.8;
  padding: 7px;
  border-radius: 5px;
}

.HeaderActivities {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.ActivitiesImages {
  display: flex;
}

.ActivitiesDrag {
  background-color: #ededed;
  border-style: dashed;
  border-color: #836767;
  width: 279px;
  height: 157px;
}

.field {
  /* clear: both; */
  margin-bottom: 10px;
}
.act_input1 {
  border: 1px solid rgba(0, 0, 0, 0.16);
  height: 25px;
  padding: 5px 10px 5px 5px;
  font-size: 15px;
  border-radius: 3px;
  width: 496px;
  outline: none;
}

.wrapperact textarea {
  width: 29%;
  resize: none;
  height: 160px;
  outline: none;
  font-size: 16px;
  margin-top: 20px;
  border-radius: 5px;
  max-height: 330px;
  caret-color: #000000;
}
textarea::placeholder {
  color: #b3b3b3;
}
textarea:is(:focus, :valid) {
  padding: 14px;
  border: 3px solid #dcdcdc;
}
textarea::-webkit-scrollbar {
  height: 0px;
}

.actbtn {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  color: #fff;
  background-color: #0066cc;
  padding: 12px 55px;
  border: none;
  border-radius: 6px;
  transition: 1000ms;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 11px;
}

.actbtn1 {
  font-family: Roboto, sans-serif;
  color: #000;
  background-color: #ffffff;
  padding: 15px 23px;
  border: 1px solid black;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: -14px 0px 0px 3px;
}

.actbtn2 {
  font-family: Roboto, sans-serif;
  color: #000;
  background-color: #ffffff;
  padding: 0px 26px;
  border: 1px solid black;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 0px 0px 0px 21px;
}

.BodyActivities {
  overflow: scroll;
  height: 89%;
  overflow-x: hidden;
}

/* .BodyActivities */

.BodyActivities::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

.BodyActivities::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 4px; /* Rounded corners of the thumb */
}

.BodyActivities::-webkit-scrollbar-thumb:hover {
  background-color: #b3b3b3; /* Color of the thumb on hover */
}

.BodyActivities::-webkit-scrollbar-track {
  background-color: #ffffff; /* Color of the track */
  border-radius: 4px; /* Rounded corners of the track */
}

.EditAct {
  background: none;
  border: none;
  font-size: large;
  color: #3c9dff;
  outline: none;
  cursor: pointer;
  padding: 8px;
  font-weight: 600;
}

.DeleteAct {
  background: none;
  border: none;
  font-size: large;
  color: #fd9c5c;
  outline: none;
  cursor: pointer;
  padding: 8px;
  font-weight: 600;
}

.RuleAct {
  background: none;
  border: none;
  font-size: large;
  color: #3c9dff;
  outline: none;
  cursor: pointer;
  padding: 8px;
  font-weight: 600;
}

.mainBoxCoupan ul {
  margin: 0;
  padding: 0;
}
.mainBoxCoupan ul {
  display: flex;
  height: 12vh;
  list-style-type: none;
  flex-direction: column;
  flex-wrap: wrap;
  border: 1px solid black;
  border-color: darkgray;
  width: 20vw;
}
.mainBoxCoupan li {
  flex: 0 0 50%;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.mainBoxCoupan li:nth-child(1) {
  flex: 0 0 100%;
}

.container-act {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.container-act img {
  object-fit: cover;
}

.container-act .btn {
  /* opacity: 0.79;
  position: absolute;
  top: 88%;
  left: 20%;
  transform: translate(-50%, -50%);
  background-color: #1b0e0e;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  text-align: center;
  padding: 17px 15px 17px 140px; */

  /* opacity: 0.79;
    position: absolute;
    top: 88%;
    left: 25%;
    transform: translate(-50%, -50%);
    background-color: #1b0e0e;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    text-align: center;
    padding: 17px 10px 17px 94px; */

  opacity: 0.79;
  position: absolute;
  top: 87%;
  left: 25%;
  transform: translate(-50%, -50%);
  background-color: #1b0e0e;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  text-align: center;
  padding: 17px 10px 17px 94px;
  width: 97px;
}

.container-act .btn2 {
  /* opacity: 0.79;
  position: absolute;
  top: 88%;
  left: 75%;
  transform: translate(-50%, -50%);
  background-color: #1b0e0e;
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  padding: 17px 139px 17px 10px;
  border: 2px solid white;
  border-top: none;
  border-bottom: none;
  border-right: none; */

  /* opacity: 0.79;
    position: absolute;
    top: 88%;
    left: 74%;
    transform: translate(-50%, -50%);
    background-color: #1b0e0e;
    color: white;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    padding: 17px 138px 17px 10px;
    border: 2px solid white;
    border-top: none;
    border-bottom: none;
    border-right: none; */

  opacity: 0.79;
  position: absolute;
  top: 87%;
  left: 75%;
  transform: translate(-50%, -50%);
  background-color: #1b0e0e;
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  padding: 17px 138px 17px 10px;
  border: 2px solid white;
  border-top: none;
  border-bottom: none;
  border-right: none;
  width: 48px;
}
.container-act .btn:hover {
  background-color: black;
}

.container-act .btn2:hover {
  background-color: black;
}
.spanbtn1 {
  position: relative;
  right: 45px;
}
.spanbtn2 {
  position: relative;
  left: 57px;
}

.custom-loader {
  width: 50px;
  height: 50px;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #e4e4ed #0000;
  animation: s6 1s infinite linear;
}
.custom-loader::before,
.custom-loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.custom-loader::before {
  border-color: #766df4 #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}
.custom-loader::after {
  margin: 8px;
}

@keyframes s6 {
  100% {
    transform: rotate(1turn);
  }
}

.mainBox-card-container-app {
  align-items: start;
  display: grid;
  /* grid-gap: 7px;
  grid-template-columns: repeat(auto-fit, 530px); */

  grid-gap: 5px;
  grid-template-columns: repeat(auto-fit, 644px);

  /* grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, 615px); */
  margin-left: 5%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 612px;
}

.mainBoxCoupanSelect {
  display: flex;
  /* padding: 7px; */
}

.mainBoxCoupanSelect ul {
  margin: 0;
  padding: 0;
}
.mainBoxCoupanSelect ul {
  display: flex;
  height: 14vh;
  list-style-type: none;
  flex-direction: column;
  flex-wrap: wrap;
  border: 1.2px solid #dfd2d2;
  width: 26vw;
}
.mainBoxCoupanSelect li {
  flex: 0 0 50%;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.mainBoxCoupanSelect li:nth-child(1) {
  flex: 0 0 100%;
}

.mainBoxGiftSelect {
  display: flex;
  /* padding: 7px; */
}

.mainBoxGiftSelect ul {
  margin: 0;
  padding: 0;
}
.mainBoxGiftSelect ul {
  display: flex;
  height: 14vh;
  list-style-type: none;
  flex-direction: column;
  flex-wrap: wrap;
  border: 1.2px solid #dfd2d2;
  width: 26vw;
}
.mainBoxGiftSelect li {
  flex: 0 0 50%;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.mainBoxGiftSelect li:nth-child(1) {
  flex: 0 0 100%;
}

.hidden-checkbox-act:checked {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hidden-checkbox-act[type="checkbox"] {
  -webkit-appearance: none;
  /* position: relative; */
  /* height: 100%;
  width: 100%;
  cursor: pointer;
  border: 3px solid transparent;
  outline: none;
  z-index: 1000; */
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  z-index: 1000;
  margin: 0px;
}

.hidden-checkbox-act[type="checkbox"]:after {
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  content: "\f111";
  font-size: 16px;
  top: 10px;
  left: 10px;
  color: #e2e6f3;
  background: white;
  border-radius: 50px;
}

.hidden-checkbox-act[type="checkbox"]:checked {
  border: 4px solid #6699ff;
}

.hidden-checkbox-act[type="checkbox"]:checked:after {
  font-weight: 900;
  content: "\f058";
  color: #6699ff;
}

.label-container-act {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: inline-block;
  border: 3px solid transparent;
}

/* .label-container-act img {
  position: absolute;
  top: 3px;
  left: 4px;
} */

.hidden-checkbox-act {
  display: none;
}

.hidden-checkbox-act:checked + .label-container-act img {
  transform: scale(1.05);
}

/* Style for the select container */
.select-container {
  width: 200px; /* Adjust the width as needed */
  position: relative;
}

/* Style for the select element */
.select-element {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none; /* Remove the default arrow in some browsers */
  cursor: pointer;
  background-color: #fff;
}

/* Style for the select arrow icon (you can use your own icon) */
.select-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

/* Style for the select options */
.select-element option {
  padding: 10px;
}

/* Style for the select when hovered */
.select-element:hover {
  border-color: #59d3fc; /* Change border color on hover */
}

/* Style for the select when focused */
.select-element:focus {
  outline: none;
  border-color: #59d3fc; /* Change border color when focused */
}

.disabled-button {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

/* .mainBoxCoupanSelectNew{
  display: flex;
  height: 11vh;
  list-style-type: none;
  flex-direction: column;
  flex-wrap: wrap;
  border: 1.2px solid #dfd2d2;
  width: 20vw;
} */

/* ___________new code___________ */
/* Style for the select container */
.select-container {
  width: 200px; /* Adjust the width as needed */
  position: relative;
}

/* Style for the select element */
.select-element {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none; /* Remove the default arrow in some browsers */
  cursor: pointer;
  background-color: #fff;
}

/* Style for the select arrow icon (you can use your own icon) */
.select-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

/* Style for the select options */
.select-element option {
  padding: 10px;
}

/* Style for the select when hovered */
.select-element:hover {
  border-color: #59d3fc; /* Change border color on hover */
}

/* Style for the select when focused */
.select-element:focus {
  outline: none;
  border-color: #59d3fc; /* Change border color when focused */
}

.disabled-button {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

/* .mainBoxCoupanSelectNew{
  display: flex;
  height: 11vh;
  list-style-type: none;
  flex-direction: column;
  flex-wrap: wrap;
  border: 1.2px solid #dfd2d2;
  width: 20vw;
} */

/* ______________________Button */
.button-4 {
  background-color: #fafbfc;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  /* box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset; */
  box-sizing: border-box;
  color: #24292e;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  /* padding: 6px 16px; */
  /* padding: 12px 8px; */
  padding: 16px 15px;
  position: relative;
  margin: 3px;
}

.dropdownforCountry {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  flex: 1; /* Expand to fill available space */
}

.button-5 {
  background-color: #fafbfc;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  /* box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset; */
  box-sizing: border-box;
  color: #24292e;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  /* padding: 6px 16px; */
  /* padding: 12px 8px; */
  padding: 8px 15px;
  position: relative;
}

.button-5:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: darkgrey;
  color: #fff;
  /* padding: 10px; */
  /* padding: 8px 15px; */
  cursor: not-allowed;
}

  .uploadBtn1[disabled] {
  border: 1px solid #999999;
  background-color: darkgrey;
  color: #fff;
  cursor: not-allowed;
}

.button-6 {
  background-color: #fafbfc;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  /* box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset; */
  box-sizing: border-box;
  color: #24292e;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  /* padding: 6px 16px; */
  /* padding: 12px 8px; */
  padding: 8px 15px;
  position: relative;
}

/* //radio button */

.box-6 {
  width: 100%;
  max-width: 360px;
  /* padding: 5px; */
  /* padding-left: 10px; */
}
.box-6 h1 {
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: 1px;
  text-align: center;
}

.custom-radio-button__container input[type="radio"] {
  display: none;
}
.custom-radio-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 11px 15px 11px;
  border: 3px solid #979797;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.custom-radio-button h3 {
  color: #979797;
  font-weight: 500;
  font-size: 1.375rem;
  letter-spacing: 1px;
  text-align: center;
}
.custom-radio-button__container
  input[type="radio"]:checked
  + .custom-radio-button {
  border: 3px solid #5858ff;
  box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.5);
}
/* .custom-radio-button__container input[type="radio"]:checked + .custom-radio-button h3 {
  color: #e94174;
}
.custom-radio-button__container input[type="radio"]:checked + .custom-radio-button .svg-designer {
  stroke: #e94174;
}
.custom-radio-button__container input[type="radio"]:checked + .custom-radio-button .svg-developer {
  fill: #e94174;
} */

/* _____________checkbox____________________ */

.checkbox-6 {
  position: relative;
  top: 18px;
  right: 20px;
}

.checkbox-7 {
  width: 2rem;
  height: 2rem;
  color: #6633ff;
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  border-radius: 50%;
  background-color: #ffffff;
  transition: background 300ms;
  cursor: pointer;
}

/* Pseudo element for check styling */
.checkbox-7::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #ccd3d8;
}

/* Checked */
.checkbox-7:checked {
  background-color: currentcolor;
}

.checkbox-7:checked::before {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

.countwordchar {
  position: relative;
  top: 18px;
  right: 31px;
}

/* [type=checkbox] {
  width: 2rem;
  height: 2rem;
  color: #6633ff;
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  border-radius: 50%;
  background-color: #FFFFFF;
  transition: background 300ms;
  cursor: pointer;
}

[type=checkbox]::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #CCD3D8;
}

[type=checkbox]:checked {
  background-color: currentcolor;
}

[type=checkbox]:checked::before {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
} */


.act-container {
  width: 100%;
  max-width: 1200px;
  /* border-radius: 4px;
  margin: 0 auto;
  padding: 40px 0; */
}

.act-content {
  /* display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  padding: 0 30px; */
}

.act-wrapper {
  width: 33.3%;
  height: 100%;
  padding: 4px;
}

.uploadBtn1 {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  width: 50%;
  height: 40px;
  font-weight: bold;
  transition: 0.3s;
  background-color: #000000;
  border-left: none;
  border-top: none;
  border-bottom: none;
  border-color: #ffffff;
  cursor: pointer;
}

.uploadBtn2 {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  width: 50%;
  height: 40px;
  font-weight: bold;
  transition: 0.3s;
  background-color: #000000;
  border: none;
  cursor: pointer;
  border-right: none;
  border-top: none;
  border-bottom: none;
  border-color: #fff;
}

.act-box {
  position: relative;
  background-color: #6870af;
  height: 231px;
  overflow: hidden;
  box-shadow: 0 1.4px 1.7px rgba(0, 0, 0, 0.017),
    0 3.3px 4px rgba(0, 0, 0, 0.024), 0 6.3px 7.5px rgba(0, 0, 0, 0.03),
    0 11.2px 13.4px rgba(0, 0, 0, 0.036),
    0 20.9px 25.1px rgba(0, 0, 0, 0.043), 0 50px 60px rgba(0, 0, 0, 0.06);

  img {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s ease-in-out;

    &:hover {
      transition: all 0.3s ease-in-out;
    }
  }
}

@media screen and (max-width: 880px) {
  .wrapper {
    width: 50%;
  }
}

@media screen and (max-width: 520px) {
  .wrapper {
    width: 100%;
  }
}

.act-spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #000000 94%, #0000) top/9px
      9px no-repeat,
    conic-gradient(#0000 30%, #000000);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 9px),
    #000 0
  );
  animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
  100% {
    transform: rotate(1turn);
  }
}

.centerspinner {
  display: flex;
  justify-content: center;
  padding: 101px;
}
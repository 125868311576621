/* .CouponContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(12, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
} */

/* .div1 {
  grid-area: 1 / 1 / 2 / 2;
  background-color: rgb(124, 236, 60);
}
.div2 {
  grid-area: 2 / 1 / 4 / 3;
  background-color: rgb(124, 236, 60);
}
.div3 {
  grid-area: 2 / 3 / 3 / 4;
  background-color: rgb(124, 236, 60);
}
.div4 {
  grid-area: 3 / 3 / 4 / 4;
  background-color: rgb(124, 236, 60);
}
.div5 {
  grid-area: 2 / 4 / 3 / 5;
  background-color: rgb(124, 236, 60);
}
.div6 {
  grid-area: 3 / 4 / 4 / 6;
  background-color: rgb(124, 236, 60);
}
.div7 {
  grid-area: 2 / 5 / 3 / 6;
  background-color: rgb(124, 236, 60);
}
.div8 {
  grid-area: 2 / 6 / 3 / 7;
  background-color: rgb(124, 236, 60);
}
.div9 {
  grid-area: 4 / 1 / 5 / 2;
  background-color: rgb(124, 236, 60);
}
.div10 {
  grid-area: 4 / 2 / 5 / 4;
  background-color: rgb(124, 236, 60);
}
.div11 {
  grid-area: 5 / 1 / 6 / 2;
  background-color: rgb(124, 236, 60);
}
.div12 {
  grid-area: 5 / 2 / 6 / 3;
  background-color: rgb(124, 236, 60);
}
.div13 {
  grid-area: 5 / 3 / 6 / 4;
  background-color: rgb(124, 236, 60);
}
.div14 {
  grid-area: 5 / 3 / 6 / 6;
  background-color: rgb(124, 236, 60);
}
.div15 {
  grid-area: 6 / 1 / 7 / 2;
  background-color: rgb(124, 236, 60);
}
.div16 {
  grid-area: 6 / 2 / 7 / 4;
  background-color: rgb(124, 236, 60);
}
.div17 {
  grid-area: 6 / 4 / 6 / 5;
  background-color: rgb(124, 236, 60);
}
.div18 {
  grid-area: 6 / 4 / 7 / 5;
  background-color: rgb(124, 236, 60);
}
.div19 {
  grid-area: 6 / 5 / 7 / 7;
  background-color: rgb(124, 236, 60);
}
.div20 {
  grid-area: 7 / 1 / 8 / 2;
  background-color: rgb(124, 236, 60);
}
.div21 {
  grid-area: 7 / 2 / 8 / 3;
  background-color: rgb(124, 236, 60);
}
.div22 {
  grid-area: 7 / 3 / 8 / 4;
  background-color: rgb(124, 236, 60);
}
.div23 {
  grid-area: 7 / 3 / 8 / 5;
  background-color: rgb(124, 236, 60);
}
.div24 {
  grid-area: 7 / 5 / 8 / 7;
  background-color: rgb(124, 236, 60);
}
.div25 {
  grid-area: 8 / 1 / 9 / 2;
  background-color: rgb(124, 236, 60);
}
.div26 {
  grid-area: 8 / 2 / 9 / 3;
  background-color: rgb(124, 236, 60);
}
.div27 {
  grid-area: 8 / 3 / 9 / 5;
  background-color: rgb(124, 236, 60);
}
.div28 {
  grid-area: 8 / 5 / 9 / 7;
  background-color: rgb(124, 236, 60);
}
.div29 {
  grid-area: 9 / 1 / 10 / 2;
  background-color: rgb(124, 236, 60);
}
.div30 {
  grid-area: 9 / 2 / 10 / 3;
  background-color: rgb(124, 236, 60);
}
.div31 {
  grid-area: 9 / 3 / 10 / 4;
  background-color: rgb(124, 236, 60);
}
.div32 {
  grid-area: 9 / 3 / 10 / 5;
  background-color: rgb(124, 236, 60);
}
.div33 {
  grid-area: 10 / 1 / 11 / 2;
  background-color: rgb(124, 236, 60);
}
.div34 {
  grid-area: 10 / 2 / 11 / 3;
  background-color: rgb(124, 236, 60);
}
.div35 {
  grid-area: 10 / 3 / 11 / 4;
  background-color: rgb(124, 236, 60);
}
.div36 {
  grid-area: 10 / 4 / 11 / 5;
  background-color: rgb(124, 236, 60);
}
.div37 {
  grid-area: 10 / 5 / 11 / 6;
  background-color: rgb(124, 236, 60);
}
.div38 {
  grid-area: 11 / 3 / 12 / 4;
  background-color: rgb(124, 236, 60);
}
.div39 {
  grid-area: 11 / 4 / 12 / 5;
  background-color: rgb(124, 236, 60);
}
.div40 {
  grid-area: 11 / 5 / 12 / 6;
  background-color: rgb(124, 236, 60);
}
.div41 {
  grid-area: 12 / 1 / 13 / 3;
  background-color: rgb(124, 236, 60);
} */

.coupon_input_name {
  border: 1px solid rgba(0, 0, 0, 0.16);
  height: 25px;
  padding: 5px 10px 5px 5px;
  font-size: 15px;
  border-radius: 3px;
  width: 496px;
  outline: none;
}

.coupon_input_quantity {
  border: 1px solid rgba(0, 0, 0, 0.16);
  height: 25px;
  padding: 5px 10px 5px 5px;
  font-size: 15px;
  border-radius: 3px;
  /* width: 238px; */
  outline: none;
}

.btnCoupon {
    font-family: Roboto, sans-serif;
    font-size: 17px;
    color: #fff;
    background-color: #6870af;
    padding: 21px 105px;
    border-radius: 13px;
    transition: 1000ms;
    transform: translateY(0);
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border: none;
    margin-bottom: 19px;

    /* font-family: Roboto, sans-serif;
    font-weight: 0;
    font-size: 14px;
    color: #fff;
    background-color: #6870af;
    padding: 21px 105px;;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
    border-radius: 36px;
    transition : 1000ms;
    transform: translateY(0);
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border: none;
    margin-bottom: 19px; */
}

/* Add this CSS to your stylesheet */
button.btnCoupon[disabled] {
  background-color: lightgray; /* Set the background color for disabled state */
  cursor: not-allowed; /* Change cursor style to not-allowed */
  /* Add any other styling you want for the disabled state */
}


.CouponContainer {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(12, 1fr);
    /* grid-column-gap: 12px;
    grid-row-gap: 12px; */

    grid-column-gap: 8px;
    /* grid-row-gap: 33px; */
    grid-row-gap: 41px;
    /* overflow-y: auto;
    max-height: 704px; */

    overflow-y: auto;
    overflow-x: hidden;
    /* max-height: 618px; */
    max-height: 697px;
  }
  
  .div1 {
    grid-area: 1 / 1 / 2 / 2;
         
  
  }
  .div2 {
    grid-area: 2 / 1 / 4 / 5;
         
  }
  .div3 {
    grid-area: 2 / 5 / 3 / 6;
         
  }
  .div4 {
    grid-area: 3 / 5 / 4 / 6;
         
  }
  .div5 {
    grid-area: 2 / 6 / 3 / 7;
         
  }
  .div6 {
    grid-area: 2 / 7 / 3 / 8;
         
  }
  .div7 {
    grid-area: 2 / 8 / 3 / 9;
         
  }
  .div8 {
    grid-area: 3 / 6 / 4 / 9;
         
  }
  .div9 {
    grid-area: 4 / 1 / 5 / 2;
         
  }
  .div10 {
    grid-area: 4 / 2 / 5 / 5;
         
  }

  .div101{
    grid-area: 4 / 5 / 5 / 5;
  }
  
  .div11 {
    grid-area: 5 / 1 / 6 / 2;
         
  }
  .div12 {
    grid-area: 5 / 2 / 6 / 8;
         
  }
  .div13 {
    grid-area: 5 / 3 / 6 / 6;
         
  }
  .div14 {
    grid-area: 6 / 1 / 7 / 2;
         
  }
  .div15 {
    /* grid-area: 6 / 2 / 7 / 4; */
    grid-area: 6 / 2 / 7 / 5;
         
  }
  .div16 {
    /* grid-area: 6 / 4 / 7 / 6; */
    grid-area: 6 / 5 / 6 / 8;
    margin-left: -103px;
         
  }
  .div17 {
    /* grid-area: 6 / 6 / 7 / 8; */
    grid-area: 6 / 7 / 7 / 9;
         
  }
  .div18 {
    grid-area: 7 / 1 / 8 / 2;
         
  }
  .div19 {
    grid-area: 7 / 2 / 8 / 4;
         
  }
  .div20 {
    grid-area: 7 / 3 / 8 / 6;
         
  }
  .div21 {
    grid-area: 7 / 5 / 8 / 9;
         
  }
  .div22 {
    grid-area: 8 / 1 / 9 / 2;
         
  }
  .div23 {
    grid-area: 8 / 2 / 9 / 3;
         
  }
  .div24 {
    grid-area: 8 / 3 / 9 / 6;
         
  }
  .div25 {
    grid-area: 8 / 6 / 9 / 9;
         
  }
  .div26 {
    grid-area: 9 / 1 / 10 / 2;
         
  }
  .div27 {
    grid-area: 9 / 2 / 10 / 3;
         
  }
  .div28 {
    grid-area: 9 / 3 / 10 / 9;
         
  }
  .div29 {
    grid-area: 10 / 1 / 11 / 2;
         
  }
  .div30 {
    grid-area: 10 / 2 / 11 / 3;
         
  }
  .div31 {
    grid-area: 10 / 3 / 11 / 5;
         
  }
  .div32 {
    grid-area: 10 / 5 / 11 / 7;
         
  }
  .div33 {
    grid-area: 10 / 7 / 11 / 9;
         
  }
  .div34 {
    grid-area: 11 / 3 / 12 / 5;
         
  }
  .div35 {
    grid-area: 11 / 5 / 12 / 7;
         
  }
  .div36 {
    grid-area: 11 / 7 / 12 / 9;
         
  }
  .div37 {
    grid-area: 12 / 1 / 13 / 3;
         
  }


  .GiftImage img{
    display: block;
    height: 62px;
    object-fit: cover;
    padding: 5px;
    width: 134px;
}
  

/* .CouponContainer {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-column-gap: 7px;
    grid-row-gap: 5px;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
       
}
.div2 {
  grid-area: 2 / 1 / 4 / 3;
       
}
.div3 {
  grid-area: 2 / 3 / 3 / 4;
       
}
.div4 {
  grid-area: 3 / 3 / 4 / 4;
       
}
.div5 {
  grid-area: 2 / 4 / 3 / 5;
       
}
.div6 {
  grid-area: 2 / 5 / 3 / 6;
       
}
.div7 {
  grid-area: 2 / 6 / 3 / 7;
       
}
.div8 {
  grid-area: 3 / 4 / 4 / 7;
       
}
.div9 {
  grid-area: 4 / 1 / 5 / 2;
       
}
.div10 {
  grid-area: 4 / 2 / 5 / 5;
       
}
.div11 {
  grid-area: 5 / 1 / 6 / 2;
       
}
.div12 {
  grid-area: 5 / 2 / 6 / 3;
       
}
.div13 {
  grid-area: 5 / 3 / 6 / 5;
       
}
.div14 {
  grid-area: 6 / 1 / 7 / 2;
       
}
.div15 {
  grid-area: 6 / 2 / 7 / 4;
       
}
.div16 {
  grid-area: 6 / 4 / 7 / 6;
       
}
.div17 {
  grid-area: 6 / 6 / 7 / 8;
       
}
.div18 {
  grid-area: 7 / 1 / 8 / 2;
       
}
.div19 {
  grid-area: 7 / 2 / 8 / 3;
       
}
.div20 {
  grid-area: 7 / 3 / 8 / 6;
       
}
.div21 {
  grid-area: 7 / 6 / 8 / 8;
       
}
.div22 {
  grid-area: 8 / 1 / 9 / 2;
       
}
.div23 {
  grid-area: 8 / 2 / 9 / 3;
       
}
.div24 {
  grid-area: 8 / 3 / 9 / 5;
       
}
.div25 {
  grid-area: 8 / 5 / 9 / 7;
       
}
.div26 {
  grid-area: 9 / 1 / 10 / 2;
       
}
.div27 {
  grid-area: 9 / 2 / 10 / 3;
       
}
.div28 {
  grid-area: 9 / 3 / 10 / 5;
       
}
.div29 {
  grid-area: 10 / 1 / 11 / 2;
       
}
.div30 {
  grid-area: 10 / 2 / 11 / 3;
       
}
.div31 {
  grid-area: 10 / 3 / 11 / 4;
       
}
.div32 {
  grid-area: 10 / 4 / 11 / 5;
       
}
.div33 {
  grid-area: 10 / 5 / 11 / 6;
       
}
.div34 {
  grid-area: 11 / 3 / 12 / 4;
       
}
.div35 {
  grid-area: 11 / 4 / 12 / 5;
       
}
.div36 {
  grid-area: 11 / 5 / 12 / 6;
       
}
.div37 {
  grid-area: 12 / 1 / 13 / 3;
       
} */


.textcrop { 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}


.textcrop1 { 
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.break-word {
  word-wrap: break-word;
}

.coupon_input_start{
  width: 144px;
  height: 31px
}
.coupon_input_end{
    width: 144px;
    height: 31px
}
.mainpage {
  display: flex;
  justify-content: space-between;
}


body {
  font-family: sans-serif;
}

header,
footer {
  text-align: center;
}

.card {
  /* background-color: orangered; */
  border-radius: 5px;
  color: white;
  padding: 9px 0 0px;
  text-align: center;
  text-shadow: 1px 1px 0 #555;
}

Customized scrollbar styles 

.card-container::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

.card-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 4px; /* Rounded corners of the thumb */
}

.card-container::-webkit-scrollbar-thumb:hover {
  background-color: #b3b3b3; /* Color of the thumb on hover */
}

.card-container::-webkit-scrollbar-track {
  background-color: #ffffff; /* Color of the track */
  border-radius: 4px; /* Rounded corners of the track */
}

input[type="file"] {
  display: none;
}

.labelimp{
  color: white;
  height: 46px;
  width: 166px;
  border-radius: 16px;
  background-color: #6870af;
  position: absolute;
  margin: auto;
  top: 93px;
  right: 84px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}



.styledcheckbox {
  width: 23px;
  height: 21px;
  margin-top: 0px;
  cursor: pointer;
}
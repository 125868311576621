.cover {
  /* padding: 0px 30px; */
  position: relative;
  /* margin-top: 206px; */
  margin-top: 154px;
}


.not-cover{

  /* padding: 0px 30px; */
    position: relative;
    /* margin-top: 204px; */


}
.left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.scroll-images {
  /* width: 100%; */
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.child img {
  /* width: 80%;
  height: 95%;
  object-fit: cover;
  object-position: center; */
  width: 68%;
    height: 83%;
    object-fit: cover;
    object-position: center;
}
.scroll-images::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.scroll-images::-webkit-scrollbar-thumb {
  /* background-color: #f6f6f6;
  border: 1px solid #ffecec; */
}

/* Hide scrollbar thumb when not in fullscreen mode */
/* .hide-scrollbar-thumb .scroll-images::-webkit-scrollbar-thumb {
  display: none;
} */

.child {
  /* min-width: 340px;
  height: 166px;
  margin: 3px -19px;
  overflow: hidden; */

  min-width: 315px;
    height: 159px;
    margin: 7px -44px;
    overflow: hidden;
}




.scrollable-container {
  background-color: rgb(240, 243, 248);
  padding: 3rem 0;
  display: flex;
  gap: 2rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-family: monospace;
  font-size: 1.2rem;
  font-weight: 600;
  width: 25rem;
  height: 10rem;
  user-select: none;
}

.section--start {
  background: rgb(255, 172, 172);
  scroll-snap-align: start;
}

.section--center {
  background: rgb(172, 185, 255);
  scroll-snap-align: center;
}

.section--end {
  background: rgb(175, 255, 172);
  scroll-snap-align: end;
}


::-webkit-scrollbar-thumb {   
  border-radius: 30px;
  /* background: -webkit-gradient(linear,left top,left bottom,from(#ff8a00),to(#da1b60));
  box-shadow: inset 2px 2px 2px rgba(255,255,255,.25), inset -2px -2px 2px rgba(0,0,0,.25);} */
 
}

.image-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.image-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 16%;
  width: 68%;
  height: 40px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 38%, rgba(0, 0, 0, 0) 100%);
}





body {
  accent-color: #6870af;
}

.galleryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-width: 620px;
  margin: 0 auto;
}

.galleryWrap .single {
  width: 200px;
  cursor: pointer;
}

.galleryWrap .single img {
  max-width: 100%;
}

.galleryWrap .single img:hover {
  transform: scale(1.02);
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  /* opacity: 0.6; */
  color: #fff;
  z-index: 9999;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 40px;
  right: 40px;
  transform: scale(2.5);
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  transform: scale(3.5);
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
  transform: scale(3.5);
}

.fullScreenImage {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50%;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.card-container {
  align-items: start;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, 318px);
  /* justify-content: center; */
  /* margin-left: 5%; */
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 612px;
}

/* Customized scrollbar styles */
.card-container::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
}

.card-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the thumb */
  border-radius: 4px; /* Rounded corners of the thumb */
}

.card-container::-webkit-scrollbar-thumb:hover {
  background-color: #b3b3b3; /* Color of the thumb on hover */
}

.card-container::-webkit-scrollbar-track {
  background-color: #ffffff; /* Color of the track */
  border-radius: 4px; /* Rounded corners of the track */
}

.card {
  /* background-color: orangered; */
  border-radius: 5px;
  color: white;
  padding: 9px 0 0px;
  text-align: center;
  text-shadow: 1px 1px 0 #555;
}

.btn {
  position: relative;
  left: 6%;
  top: 28px;
}

/* Hide Button */
.container {
  position: relative;
  border-radius: 5px;
  color: white;
  /* padding: 9px 0 0px; */
  text-align: center;
}




.button {
  position: absolute;
  width: 500px;
  /* top: 24px; */
  top: 9px;
  left: 1px;
  text-align: center;
  opacity: 0;
}

.container:hover .button {
  opacity: 1;
}

.btn.button.visible {
  opacity: 1;
}

.btn.button.visible1{
  opacity: 1;
}
.styled-checkbox {
  width: 23px;
  height: 21px;
  /* margin-top: 0px; */
  margin-top: -3px;
  position: absolute;
  left: 13px;
  cursor: pointer;
}

/* input[type="checkbox"] {
  background-color: red;
}

input[type="checkbox"]:checked {
  background-color: blue;
} */





.checkbox-wrapper-21 .control {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 18px;
}
.checkbox-wrapper-21 .control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox-wrapper-21 .control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
}
.checkbox-wrapper-21 .control:hover input ~ .control__indicator,
.checkbox-wrapper-21 .control input:focus ~ .control__indicator {
  background: #ccc;
}
.checkbox-wrapper-21 .control input:checked ~ .control__indicator {
  background: #2aa1c0;
}
.checkbox-wrapper-21
  .control:hover
  input:not([disabled]):checked
  ~ .control__indicator,
.checkbox-wrapper-21 .control input:checked:focus ~ .control__indicator {
  background: #0e647d;
}
.checkbox-wrapper-21 .control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.checkbox-wrapper-21 .control__indicator:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox-wrapper-21 .control input:checked ~ .control__indicator:after {
  display: block;
}
.checkbox-wrapper-21 .control--checkbox .control__indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.checkbox-wrapper-21
  .control--checkbox
  input:disabled
  ~ .control__indicator:after {
  border-color: #7b7b7b;
}




.cover-image {
  object-fit: cover;
  width: 301px;
  height: 171px;
}


/* CSS Spinner */
.spinner {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}





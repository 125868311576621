.SubscriptionContainer {
  display: grid;
  /* grid-template-columns: repeat(5, 1fr); */
  grid-template-columns: repeat(13, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 44px;
}

.subs1 {
  grid-area: 2 / 1 / 3 / 2;
    
}
.subs2 {
  grid-area: 2 / 2 / 3 / 5;
    
}
.subs3 {
  grid-area: 3 / 1 / 4 / 2;
    
}
.subs4 {
  grid-area: 3 / 2 / 4 / 4;
    
}
.subs5 {
  grid-area: 4 / 1 / 5 / 2;
    
}
.subs6 {
  grid-area: 4 / 2 / 5 / 3;
    
}
.subs7 {
  grid-area: 4 / 3 / 5 / 5;
    
}
.subs8 {
  grid-area: 5 / 1 / 6 / 2;
    
}
.subs9 {
  grid-area: 5 / 2 / 6 / 3;
    
}
.subs10 {
  grid-area: 5 / 3 / 6 / 5;
    
}
.subs11 {
  grid-area: 6 / 1 / 7 / 2;
    
}
.subs12 {
  grid-area: 6 / 2 / 7 / 3;
    
}
.subs13 {
  grid-area: 6 / 3 / 7 / 5;
    
}

.footerBtn14{
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 175px;
}

.sub_input1 {
    border: 1px solid rgba(0, 0, 0, 0.16);
    height: 25px;
    padding: 5px 10px 5px 5px;
    font-size: 15px;
    border-radius: 3px;
    width: 202px;
    outline: none;
  }

  .tdvaluesubtitle {
    padding: 8px;
    border: 1px solid #e1e1e1;
    border-top: none;
    text-align: center;
  }
  .cardsubs{
    border: 1px solid rgba(0, 0, 0, 0.16);
    padding: 9px 30px;
    font-size: 15px;
    border-radius: 3px;
    width: max-content;
    outline: none;
  }
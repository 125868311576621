.header-app {
  display: flex;
  justify-content: space-between;
  padding: 8px 0px 26px 0px;
}

.footer-app {
    margin-top: 1rem;
    background-color: rgb(255, 255, 255);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    /* padding: 41px 0px 41px 0px; */
    justify-content: center;
}

.btn-save {
  font-family: Roboto, sans-serif;
  font-weight: 0;
  font-size: 14px;
  color: #fff;
  background-color: #6870af;
  padding: 13px 53px;

  border-radius: 7px;
  transition: 1000ms;
  transform: translateY(0);
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline-style:none;
  margin-right: 64px;
}

.btn-cancel {
    /* font-family: Roboto, sans-serif;
    font-weight: 0;
    font-size: 14px;
    color: #000;
    background-color: #f0f0f0;
    padding: 13px 53px;
    border-radius: 7px;
    transition: 1000ms;
    transform: translateY(0);
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border: none;
    outline-style:none; */
    font-family: Roboto, sans-serif;
  font-weight: 0;
  font-size: 14px;
  color: black;
  background-color: #f0f0f0;
  padding: 13px 53px;

  border-radius: 7px;
  transition: 1000ms;
  transform: translateY(0);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border: none;
  outline-style:none;
  }


  .cover-image-app{
    object-fit: cover;
    width: 252px;
    height: 182px;
  }


  .card-container-app{
    align-items: start;
    display: grid;
    grid-gap: 7px;
    grid-template-columns: repeat(auto-fit, 256px);
    margin-left: 5%;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 612px;
  }
.btn_order_search {
    border-radius: 9px;
    padding: 11px 36px 11px 36px;
    border: none;
    margin: 0px 0px 0px 0px;
    background: #6870af;
    color: #fff;
    cursor: pointer;
  }



   .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 600px;
    height: 448px;
    max-width: 80%;
    text-align: center;
    position: relative;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  
  .modal-header h2 {
    margin: 0;
    font-size: 1.5rem;
  }
  
  .modal-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .modal-body {
    max-height: 400px;
    overflow-y: auto;
  }


  
  .points-info {
    text-align: left;
    padding-right: 8px;
}

.points-info table {
    width: 100%;
}

.points-info .entry {
    display: table-row;
}

.points-info .entry td {
    padding: 5px;
}

.points-info .entry td:nth-child(1) {
    text-align: left;
    width: 30%;
}

.points-info .entry td:nth-child(2) {
    text-align: center;
    width: 20%;
    font-weight: 700;
    color: green;
}

.points-info .entry td:nth-child(3) {
    text-align: right;
    width: 20%;
    font-weight: 700;
}

.points-info .entry .negative {
    color: #a44343;
}

.dateinfo{
    font-size: smaller;
    /* font-weight: 800; */
    color: darkgray;
}

.infosub{
  font-weight: 800;
  color: darkgray;
}


  .modal-body::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
  }
  
  .modal-body::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the thumb */
    border-radius: 4px; /* Rounded corners of the thumb */
  }
  
  .modal-body::-webkit-scrollbar-thumb:hover {
    background-color: #b3b3b3; /* Color of the thumb on hover */
  }
  
  .modal-body::-webkit-scrollbar-track {
    background-color: #ffffff; /* Color of the track */
    border-radius: 4px; /* Rounded corners of the track */
  }

  .loader_small {
    width: 48px;
    height: 48px;
    border: 5px solid #d0d0d0;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }



  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid;
    border-color: #FF3D00 transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-left: 3vh;

  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

  .loadercenter{
    display: flex;
    justify-content: center;
    padding: 175px;
  }

  .coupon_input_start{
    width: 144px;
    height: 31px
  }

.react-datepicker{
  position: absolute;
    top: 71px;
    right: 172px;
}

/* //acc */
.accordion-wrapper {
  & + * {
    margin-top: 0.5em;
  }
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  font-weight: 600;
  cursor: pointer;
  color: #666;
  padding: 0.5em 1.5em;
  border: solid 1px #ccc;
  border-radius: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid currentColor;
  }

  &:hover,
  &.open {
    color: black;
  }

  &.open {
    &::after {
      content: "";
      border-top: 0;
      border-bottom: 5px solid;
    }
  }
}

.accordion-content {
  padding: 1em 1.5em;
}

 /* new code */
 nav {

 }
 
 .navbar {
   transform: translateX(-100%);
 }
 
 .nav-btn {
   position: absolute;
   transform: translateX(38px);
   top: 20px;
   right: 0;
   width: 40px;
   height: 60px;
   border-top-right-radius: 5px;
   border-bottom-right-radius: 5px;
   display: flex;
   justify-content: center;
   align-items: center;
 
   outline: none;
   border: none;
   font-size: 1rem;
   padding: 5px 10px;
   cursor: pointer;
   background: transparent;
 
 }
 
 .logo {
   display: block;
   width: 100px;
   margin: auto;
   background: transparent;
 }
 
 .logo img {
   display: block;
   width: 100%;
   height: 100%;
 }
 
 .nav-link {
   text-decoration: none;
   color: #000; 
   padding: 8px;
   display: flex;
   align-items: center;
   color: #fff;
   text-decoration: none;
   padding: 10px 1rem;
 
   margin-bottom: 5px;
 }
 
 .nav-link:hover {
   background-color: #ffffff;
   color: #000;
 }
 
 .nav-link span {
   margin-left: 10px;
 }
 
 .nav-top {
   margin-top: 10rem;
 }
 
 .mobile-nav {
   background-color: #ececec;
   color: #000000;
   width: 100%;
   height: 40px;
   display: none;
 }
 
 .mobile-nav-btn {
   color: #000000;
   background: transparent;
   outline: none;
   border: none;
   margin: 0 10px;
 }
 
 @media only screen and (max-width: 768px) {
   nav {
     width: 40%;
     padding: 10px;
   }
 }
 
 @media only screen and (max-width: 450px) {
   .mobile-nav {
     display: flex;
     align-items: center;
   }
   nav {
     width: 70%;
     top: 38px;
     min-height: calc(100vh - 38px);
   }
 
   .nav-btn {
     display: none;
   }
 }
 
 .mainpage {
   display: flex;
   justify-content: space-between;
   margin: 28px 69px 0px 82px;
 }
 
 .tabledata {
   padding: 50px 0% 1% 18%;
 }
 .nav-link.active {
   background-color: #000; 
   color: #fff; 
 }
 
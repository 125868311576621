.headerNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 90px;
}

.NavBarDef a {
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
}

.headerNav nav ul {
    display: flex;
    flex-direction: row;
}

.headerNav nav ul li {
    list-style-type: none;
    margin-left: 3rem;
}

.lang_Drop{
    position: absolute;
    right: 144px;
    top: 22px;
}







